import React, { useState, useEffect } from "react";
import { currencies } from "../utils/currencies";
import useStore from "../store";

export const ChainCardGroup = ({ cards, isExpanded }) => {
  const [cardsPerRow, setCardsPerRow] = useState(5);
  const { chains, activeCurrency, conversionRate } = useStore();

  const totalPortfolioValue = cards?.reduce(
    (total, chain) => total + chain.net_asset_worth, 0
  )
  function formatAsset(value) {
    if (value === undefined || value === null) return "N/A"; // Handle undefined or null values
    const fractionDigits = Math.min(
      6,
      value.toString().split(".")[1]?.length || 0
    );
    return value.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setCardsPerRow(2);
      } else if (window.innerWidth < 768) {
        setCardsPerRow(2);
      } else if (window.innerWidth < 1024) {
        setCardsPerRow(3);
      } else if (window.innerWidth < 1280) {
        setCardsPerRow(4);
      } else {
        setCardsPerRow(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getValue = (item) => {
    const totalNetAssetWorth = cards
    .filter(item => item.assets.some(asset => asset.verified))
    .reduce((sum, item) => sum + item.net_asset_worth, 0);

    const a =  item.assets?.some((asset) => asset.verified) // Check if at least one asset is verified
      ? totalNetAssetWorth > 0
        ? Number(
            ((item.net_asset_worth / totalNetAssetWorth) * 100).toFixed(2)
          ) + "%"
        : 0.00
      : "0.00%"
return a || 0;
        }

  return (
    <div>

      <div className="flex flex-wrap gap-4 my-2">
        {cards
          .slice(0, isExpanded ? cards.length : cardsPerRow)
          .sort((a, b) => b.net_asset_worth - a.net_asset_worth) // Sort cards by net_asset_worth in descending order
          .map((item, index) => (
            <div
              key={index}
              className={`min-w-[calc(50%-16px)] sm:min-w-[calc(50%-16px)] md:min-w-[calc(33.33%-16px)] lg:min-w-[calc(25%-16px)] xl:min-w-[calc(20%-16px)] bg-slate-200 dark:bg-[#141719] p-4 rounded-lg flex flex-row items-start justify-between ${
                index === cards.length - 1 ? "gap-0" : "gap-4"
              }`}
            >
              <div className="flex flex-col">
                <div className="w-full flex flex-row items-start gap-2">
                  <div className="flex w-[80%]">
                    <div className="">
                      <img
                        src={
                          chains?.find(
                            (chain) =>
                              chain?.name?.toLowerCase() === item?.chain
                          )?.logo_url
                        }
                        alt="Chain logo"
                        className="h-[22px] w-[22px]"
                      />
                    </div>
                    <div className="flex justify-start">
                      <div className="text-[#6D7072] text-[14px] pl-2">
                        {
                          chains?.find(
                            (chain) =>
                              chain?.name?.toLowerCase() === item?.chain
                          )?.name
                        }
                      </div>
                    </div>
                  </div>
                  <div className="w-[20%] flex text-black dark:text-[#FFFFFF] text-[14px]">
                      {getValue(item)}
                  </div>
                </div>
                <div className="w-full flex flex-col text-[16px] pl-6">
                  {
                    currencies?.find(
                      (currency) =>
                        currency["Currency Code"].toLowerCase() ===
                        activeCurrency
                    )?.["Currency Symbol"]
                  }
                  {formatAsset(
                    item?.assets?.some((asset) => asset.verified) // Check if at least one asset is verified
                      ? item?.net_asset_worth === undefined ||
                        item?.net_asset_worth === null
                        ? "0"
                        : item?.net_asset_worth * conversionRate
                      : "0" // If no verified assets, show 'N/A'
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};