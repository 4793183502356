import SendIcon from "../assets/Icons/send.svg";
import ReceiveIcon from "../assets/Icons/Mint1.svg";
import MintIcon from "../assets/Icons/Mint.svg";
import SwapIcon from "../assets/Icons/swap.svg";
import ApproveIcon from "../assets/Icons/approve.svg";
import ContractIcon from "../assets/Icons/contract.svg";

export const filters = [
  { name: "Send", key: "send", icon: MintIcon },
  { name: "Receive", key: "receive", icon: SwapIcon },
  { name: "Mint", key: "mint", icon: ReceiveIcon },
  { name: "Swap", key: "swap", icon: SendIcon },
  { name: "Approve", key: "approve", icon: ApproveIcon },
  { name: "Contract Execution", key: "contract_execution", icon: ContractIcon },
];
