import axios from "axios";

const REACT_APP_USERAPI = process.env.REACT_APP_USERAPI;
// const REACT_APP_USERAPI = '';

export const isAddressValid = async (address) => {
  const response = await axios.get(
    `${REACT_APP_USERAPI}/v1/address/is-address-valid/${address}`
  );
  return response.data.data.isValid;
  //   return true;
};

export const resolveEns = async (ens) => {
  const response = await axios.get(
    `${REACT_APP_USERAPI}/v1/address/resolve-ens/${ens}`
  );
  return response.data.data.publicAddress;
};

export const resolveAddress = async (address) => {
  const response = await axios.get(
    `${REACT_APP_USERAPI}/v1/address/resolve-address/${address}`
  );
  return response.data.data.safleID;
};

export const resolveSafleId = async (safleId) => {
  const response = await axios.get(
    `${REACT_APP_USERAPI}/v1/address/resolve-safleid/${safleId}`
  );
  return response.data.data.publicAddress;
};

export const supportedChains = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/supported-chains`
    );
    return response.data.data.chains;
  } catch {
    return [];
  }
};

export const assetsOnChains = async (chain, address) => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/asset/tokens/${chain}/${address}`
    );
    return response?.data?.data;
  } catch {
    return [];
  }
};

export const NFTsOnChains = async (chain, address) => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/asset/nft/${chain}/${address}`
    );
    return response?.data?.data;
  } catch {
    return [];
  }
};

export const NFTPrice = async (chain, address, tokenId) => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/asset/nft-price/${address}/${tokenId}`
    );
    return response?.data?.data?.nftPrice;
  } catch {
    return [];
  }
};

export const TxnOnChains = async (chain, address) => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/transactions/${chain}/${address}`
    );
    return response?.data?.data;
  } catch {
    return [];
  }
};

export const fetchConversionRate = async (symbol) => {
  try {
    const response = await axios.get(
      `${REACT_APP_USERAPI}/v1/asset/conversion-rate/${symbol}`
    );
    return response?.data?.data?.conversion_rate;
  } catch {
    return 1;
  }
};
