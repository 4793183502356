import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import safleLogo from "../assets/SafleLens.svg";
import safleSmallLogo from "../assets/safle-logo-mobile.svg";
import Openinnew from "../assets/Icons/Open-in-new.svg";
import tokensIcon from "../assets/Icons/Dashboard.svg";
import nftsIcon from "../assets/Icons/NFTs.svg";
import transactionsIcon from "../assets/Icons/Transaction.svg";
import featureRequestIcon from "../assets/Icons/Feature.svg";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import SafleLogoLightMode from "../assets/Icons/safle-logo-lightMode.svg";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import { HiGlobeAsiaAustralia } from "react-icons/hi2";
import { BiLogoTelegram } from "react-icons/bi";
import useStore from "../store";

export const Sidebar = ({ walletId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, sideBar, setRefresh } = useStore();
  const [sideBarOpen, setsideBarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useStore((state) => state.theme);

  const handleClickOutside = (event) => {
    if (sideBarOpen.current && !sideBarOpen.current.contains(event.target)) {
      setsideBarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userWallet =
    user && user?.safleId
      ? user?.safleId
      : user?.ENS
      ? user?.ENS
      : user?.address
      ? user?.address
      : walletId;

  const navItems = [
    {
      name: "Tokens",
      path: `/${userWallet ? `${userWallet}/tokens` : ""}`,
      icon: tokensIcon,
    },
    {
      name: "NFTs",
      path: `/${userWallet ? `${userWallet}/nfts` : ""}`,
      icon: nftsIcon,
    },
    {
      name: "Transactions",
      path: `/${userWallet ? `${userWallet}/transactions` : ""}`,
      icon: transactionsIcon,
    },
    // {
    //   name: "Request a Feature",
    //   path: "/featureRequest",
    //   icon: featureRequestIcon,
    // },
  ];

  return (
    <div
      className={`md:h-screen sm:h-screen duration-300 ${
        isExpanded ? "w-[20%]" : "w-20"
      } bg-slate-100 text-black dark:bg-[#1A1D20] dark:text-white ${
        sideBar ? "flex" : "hidden"
      } absolute h-[92%] top-16 md:top-0 md:relative md:flex flex-col justify-between z-10`}
    >
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className="hidden md:block absolute right-[-12px] top-[100px] rounded-full bg-slate-700 p-1 cursor-pointer"
      >
        <img
          src={ToggleIcon}
          alt="Check Icon"
          className={`invert h-[17px] w-[17px] ${
            isExpanded ? "rotate-90" : "-rotate-90"
          }`}
        />
      </div>
      <div>
        {/* Logo */}
        <div
          className="flex items-center justify-center mt-8 py-4 cursor-pointer"
          onClick={() => navigate("/")}
        >
          {isExpanded ? (
            theme === "light" ? (
              <img
                src={SafleLogoLightMode}
                alt="Safle Logo"
                className="w-[9rem] h-[3rem] mr-2"
              />
            ) : (
              <img
                src={safleLogo}
                alt="Safle Logo"
                className="w-[9rem] h-[3rem] mr-2"
              />
            )
          ) : (
            <img src={safleSmallLogo} alt="Small Logo" className="h-8" />
          )}
        </div>

        {/* Navigation Buttons */}
        <nav className="mt-8">
          <ul className="space-y-2">
            {navItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={
                    user?.address || user?.safleId || user?.ENS
                      ? item.path
                      : "/"
                  }
                  className={({ isActive }) =>
                    isActive && location.pathname !== "/"
                      ? "block py-2.5 px-4 border-l-4 border-blue-500 text-white"
                      : "block py-2.5 px-4 pl-5 hover:bg-slate-200 dark:hover:bg-gray-700 text-[#6D7072]"
                  }
                  onClick={(e) => {
                    const isOnTokensPage =
                      location.pathname === item.path && item.name === "Tokens";
                    // Prevent navigation if already on the Tokens page
                    if (isOnTokensPage) {
                      e.preventDefault();
                      return;
                    }
                    if (item.name === "Tokens") {
                      setRefresh((prev) => prev + 1);
                    } 
                  }}
                >
                  <div className="flex">
                    <img
                      src={item.icon}
                      alt={`${item.name} icon`}
                      className="mr-2 invert"
                    />
                    {isExpanded && (
                      <div className="flex text-[#6D7072] w-40">
                        {item.name}
                      </div>
                    )}
                  </div>
                </NavLink>
              </li>
            ))}
            <li>
              <a
                href="https://hgbopbs0vss.typeform.com/LensFeedback"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2.5 px-4 rounded hover:bg-slate-200 dark:hover:bg-gray-700 dark:hover:bg-[#F2F2F2]"
              >
                <div className="flex text-[#6D7072]">
                  <img
                    src={featureRequestIcon}
                    alt="icon"
                    className="mr-2 invert"
                  />
                  {isExpanded && <div>Request a Feature</div>}
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="mb-4">
        {isExpanded ? (
          <>
            <div className="w-full  h-40  flex flex-col flex-center text-[#6D7072]">
              <div className="text-md mt-2 mb-2 items-center text-center">
                Be a Part of Safle Community
              </div>
              <div className="mt-3 w-full items-center justify-items-center grid grid-cols-3 gap-8 row-span-1">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://discord.com/invite/getsafle",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <FaDiscord size={18} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://x.com/GetSafle",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <FaXTwitter size={18} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://t.me/saflechat",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <BiLogoTelegram size={18} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/getsafle/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <FaInstagram size={18} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://safle.medium.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <FaMedium size={18} />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://safle.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <HiGlobeAsiaAustralia size={20} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Bottom Links */}
        <nav>
          <ul className="space-y-2">
            <li>
              <a
                href="https://safle.com/termsAndConditions"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2.5 px-4 rounded hover:bg-slate-200 dark:hover:bg-gray-700"
              >
                <div className="flex text-[#6D7072]">
                  <img src={Openinnew} alt="icon" className="mr-2 invert" />
                  {isExpanded && <div>Terms & Conditions</div>}
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://safle.com/privacyPolicy"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2.5 px-4 rounded hover:bg-slate-200 dark:hover:bg-gray-700"
              >
                <div className="flex text-[#6D7072]">
                  <img src={Openinnew} alt="icon" className="mr-2 invert" />
                  {isExpanded && <div>Privacy Policy</div>}
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
