import React, { useEffect, useState } from "react";
import {
  ProfileInfo,
  NetworkDropdown,
  NFTCard,
  RefreshGroup,
} from "../components";
import { NFTsOnChains } from "../api/userApi";
import useStore from "../store";
import { useInView } from "../hooks/useInView";

export const NFTsScreen = ({ children }) => {
  const {
    user,
    chains,
    activeChains,
    nftCollection,
    setNftCollection,
    setNftRefreshTime,
  } = useStore();
  const [nftList, setNftList] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerLoad = 10;
  const [visibleNFTs, setVisibleNFTs] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [ref, isInView] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    async function fetchingNFTs() {
      if (chains && chains?.length > 0) {
        setLoading(true);
        const NFTList = await Promise.all(
          chains.map(async (chain) => {
            return await NFTsOnChains(chain?.name?.toLowerCase(), user.address);
          })
        );
        setLoading(false);
        setNftCollection(NFTList);
        const timeStamp = Date.now();
        setNftRefreshTime(timeStamp);
      }
    }
    fetchingNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains]);

  useEffect(() => {
    const allNFTs = nftCollection
      ?.filter((chain) => activeChains.includes(chain?.chain?.toLowerCase()))
      ?.flatMap(({ nfts, chain }) =>
        nfts.map((nft) => ({ ...nft, chainName: chain }))
      )
      .sort((a, b) =>
        a?.asset_contract_name?.localeCompare(b.asset_contract_name)
      );
    setNftList(allNFTs);
    setVisibleNFTs(allNFTs?.slice(0, itemsPerLoad));
    if(allNFTs.length>= itemsPerLoad){
      setHasMore(true);
    }
  }, [nftCollection, activeChains]);

  useEffect(() => {
    if (nftList?.length > visibleNFTs?.length) {
      const currentLength = visibleNFTs.length;
      const nextBatch =
        currentLength + itemsPerLoad > nftList?.length
          ? nftList.slice(currentLength, nftList?.length)
          : nftList.slice(currentLength, currentLength + itemsPerLoad);
      setVisibleNFTs((prevList) => [...prevList, ...nextBatch]);
      setHasMore(currentLength + itemsPerLoad < nftList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return (
    <>
      <div className="flex flex-col text-white w-full divide-y-[1px] divide-[#1A1D20]">
        <ProfileInfo screenName={"nft"} />
        <div
          className="flex flex-col items-center relative"
          style={{ zIndex: 100 }}
        >
          <div
            className="flex flex-row w-full justify-between mx-4 my-2 md:justify-start md:gap-4 py-[1rem] pl-[1rem]  duration-300 sticky top-[-17px] bg-white dark:bg-[#101113]"
            style={{ zIndex: 100 }}
          >
            <NetworkDropdown />
            <div className="flex flex-col items-center md:hidden">
              <RefreshGroup />
            </div>
          </div>

          <div>
            {loading ? (
              <div className="border-t-4 border-blue-500 rounded-full w-10 h-10 animate-spin"></div>
            ) : activeChains.length === 0 ? (
              <div>
                <div className="text-red-500">
                  Please select any Network to see available NFTs.
                </div>
              </div>
            ) : visibleNFTs.length === 0 ? (
              <div>
                <div className="text-red-500">
                  No NFTs available on this chain.
                </div>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 4xl:grid-cols-8 gap-4">
                  {visibleNFTs.map((nft, index) => (
                    <NFTCard
                      key={index}
                      nft={nft}
                      image={nft?.image_url}
                      name={nft?.asset_contract_name}
                      token_id={nft?.token_id}
                    />
                  ))}
                </div>
                {hasMore && (
                  <div
                    ref={ref}
                    className="w-full flex justify-center items-center"
                  >
                    <div className="border-t-4 border-blue-500 rounded-full w-10 h-10 animate-spin" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
