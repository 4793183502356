import React, { useState, useEffect, useRef } from "react";
import useStore from "../store";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import { RiSpam2Line } from "react-icons/ri";
import { MdHideSource } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

const filters = [
  { name: "Hide tokens < 1USD", key: "hideLowBalance", icon: MdHideSource },
  { name: "Hide spam tokens", key: "hideSpam", icon: RiSpam2Line },
];

export const MoreTokenFilter = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { tokenFilters, setTokenFilters } = useStore();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilter = (key) => {
    setTokenFilters(
      tokenFilters.includes(key)
        ? tokenFilters.filter((name) => name !== key)
        : [...tokenFilters, key]
    );
  };

  const toggleAll = () => {
    if (tokenFilters.length === filters.length) {
      setTokenFilters([]);
    } else {
      setTokenFilters(filters.map((filter) => filter.key.toLowerCase()));
    }
  };

  const getSelectedFiltersDisplay = () => {
    return filters
      .filter((filter) => tokenFilters.includes(filter.key.toLowerCase()))
      .map((filter) => (
        <div key={filter.key} className="flex items-center mr-2">
          <filter.icon className="w-4 h-4 " />
        </div>
      ));
  };

  const allSelected = tokenFilters.length === filters.length;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-4 py-2 bg-transparent text-black dark:text-white rounded-3xl border-[1px] border-[#25292D] flex flex-row items-center gap-1"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {tokenFilters.length > 0 && (
          <div className="flex items-center">{getSelectedFiltersDisplay()}</div>
        )}
        <span>More</span>
        <img
          src={ToggleIcon}
          alt="toggle"
          className={`ml-2 h-[17px] w-[17px] invert-0 dark:invert transition-transform ${
            dropdownOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {dropdownOpen && (
        <div className="absolute mt-2 w-64 bg-white dark:bg-[#141719] rounded-md shadow-lg z-10 border dark:border-gray-200 border-gray-700">
          <div className="py-1">
            <div
              onClick={toggleAll}
              className="flex items-center justify-end px-4 py-2 cursor-pointer border-b border-gray-700"
            >
              <span className="">All</span>
              <div className="flex-shrink-0 w-5 h-5 ml-3 border dark:border-gray-200 border-gray-700 rounded-md flex items-center justify-center">
                {allSelected && <FaCheck className="text-black dark:text-white text-xs" />}
              </div>
            </div>
            {filters.map((filter) => (
              <div
                key={filter.key}
                onClick={() => toggleFilter(filter.key.toLowerCase())}
                className="flex items-center justify-between px-4 py-2 cursor-pointer"
              >
                <div className="flex items-center gap-2">
                  {filter.icon && <filter.icon className="w-5 h-5" />}
                  <span>{filter.name}</span>
                </div>
                <div className="flex-shrink-0 w-4 h-4  rounded-sm flex items-center justify-center">
                  {tokenFilters.includes(filter.key.toLowerCase()) && (
                    <FaCheck className="text-black dark:text-white text-xs" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
