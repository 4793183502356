import React, { useState, useEffect } from "react";
import Blockie from "../utils/blockies";
import Blockies from "blockies-react-svg";
import useStore from "../store";
import { CurrencyDropDown } from "./CurrencyDropDown";
import { currencies } from "../utils/currencies";
import { ChainCardGroup } from "./ChainCardGroup";
import { RefreshGroup } from "./RefreshGroup";
import makeBlockiesUrl from "blockies-react-svg/dist/es/makeBlockiesUrl.mjs";

export const ProfileInfo = ({ showChainWorth, screenName }) => {
  const { user, chainWorth, conversionRate, activeCurrency, data } = useStore();
  const [totalWorth, setTotalWorth] = useState(0);
  const [activeCurrencySymbol, setActiveCurrencySymbol] = useState("$");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        if (screenName === "token") {
          setShowRefresh(true);
        }
        if (screenName === "nft") {
          setShowRefresh(false);
        }
        if (screenName === "txn") {
          setShowRefresh(false);
        }
        //     setCardsPerRow(2);
        //   } else if (window.innerWidth < 768) {
        //     setCardsPerRow(2);
        //   } else if (window.innerWidth < 1024) {
        //     setCardsPerRow(3);
        //   } else if (window.innerWidth < 1280) {
        //     setCardsPerRow(4);
      } else {
        if (screenName === "token") {
          setShowRefresh(true);
        }
        if (screenName === "nft") {
          setShowRefresh(true);
        }
        if (screenName === "txn") {
          setShowRefresh(false);
        }
        // setCardsPerRow(5);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chainWorth && chainWorth.length > 0) {
      let totalvalue = 0;
      chainWorth.forEach((item) => {
        // Check if the assets are verified before adding to totalvalue
        if (item.assets && item.assets.length > 0) {
          const hasVerifiedAssets = item.assets.some((asset) => asset.verified);
          // item.assets.forEach(asset => {
          if (hasVerifiedAssets) {
            totalvalue += item.net_asset_worth; // Only add if verified
          }
          // });
        }
      });

      if (totalvalue) {
        setTotalWorth(totalvalue * conversionRate);
      }
    }

    if (activeCurrency === "usd") {
      setActiveCurrencySymbol("$");
    } else {
      const activeCurr = currencies?.find(
        (currency) => currency["Currency Code"].toLowerCase() === activeCurrency
      );
      setActiveCurrencySymbol(activeCurr?.["Currency Symbol"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainWorth, conversionRate]);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col md:flex-row justify-between text-black dark:text-white mx-4 w-full h-[150px] p-2">
        <div className="flex flex-row items-center">
          <div className="h-[70px] w-[70px] md:h-[120px] md:w-[120px] bg-slate-100 dark:bg-[#141719] flex justify-center items-center rounded-[20px] border-[1px] border-[#1A1D20] p-4 mr-4 ">
            {user?.address && (
              <img
                className="rounded-md"
                src={makeBlockiesUrl(user?.address)}
                alt="blockie"
              />
            )}
          </div>
          <div className="flex flex-col">
           <div className="flex flex-col">
              <div className="w-full overflow-hidden text-ellipsis">
                {user?.safleId && user?.safleId !== "" && user.safleId !== "Invalid address." ?(
                  <p className="text-2xl dark:text-white text-black">{user.safleId}</p>
                ) : user?.ENS ? (
                  <p className="text-2xl dark:text-white text-black">{user.ENS}</p>
                ) : user?.address ? (
                  <p className="text-2xl dark:text-white text-black">{user.address}</p>
                ) : null}
              </div>

              {!user?.safleId && !user?.address && user?.ENS && (
                <div className="w-full overflow-hidden text-ellipsis text-sm text-[#6D7072]">
                  <p>{user.ENS}</p>
                </div>
              )}

              {/* Display address only if safleId is not "Invalid address." or if ENS is present */}
              {user?.address && ((user?.safleId !== "Invalid address." && user?.safleId !== "" )|| user?.ENS)  && (
                <div className="w-full overflow-hidden text-ellipsis text-sm text-[#6D7072]">
                  <p>{user.address}</p>
                </div>
              )}
            </div>


            {/* <div className="flex flex-col">
              <div className="w-full overflow-hidden text-ellipsis">
                {user?.safleId ? (
                  <p className="text-2xl dark:text-white text-black">{user.safleId}</p>
                ) : user?.ENS ? (
                  <p className="text-2xl dark:text-white text-black">{user.ENS}</p>
                ) : user?.address ? (
                  <p className="text-2xl dark:text-white text-black">{user.address}</p>
                ) : null}
              </div>

              {!user?.safleId && !user?.address && user?.ENS && (
                <div className="w-full overflow-hidden text-ellipsis text-sm text-[#6D7072]">
                  <p>{user.ENS}</p>
                </div>
              )}

              {(user?.safleId || user?.ENS) && user?.address && (
                <div className="w-full overflow-hidden text-ellipsis text-sm text-[#6D7072]">
                  <p>{user.address}</p>
                </div>
              )}
            </div> */}

            <div className="text-[13px] text-[#6D7072] pt-6">
              Portfolio Value
            </div>
            <div className="flex flex-row items-center">
              <CurrencyDropDown />
              <div className="ml-4 text-[20px]  w-[12rem]">
                {activeCurrencySymbol}
                {totalWorth
                  ? totalWorth.toLocaleString("en-IN", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2, // Show max two digits after the decimal
                    })
                  : "0"}
              </div>
            </div>
          </div>
        </div>
        {showRefresh && (
          <div className="flex flex-col-reverse pr-[2rem]">
            <RefreshGroup
              showChainWorth={showChainWorth}
              handleExpandClick={handleExpandClick}
            />
          </div>
        )}
      </div>
      {showChainWorth && (
        <div className="w-full mx-auto p-4">
          <ChainCardGroup cards={chainWorth} isExpanded={isExpanded} />
        </div>
      )}
    </div>
  );
};
