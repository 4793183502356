import { c } from "blockies-react-svg/dist/es/blockies-a884f18e.mjs";
import {
  isAddressValid,
  resolveEns,
  resolveSafleId,
  resolveAddress,
} from "../api/userApi";

export const isPublicAddress = (input) => {
  // Regex to check if input is a valid Ethereum address
  return /^(0x)?[0-9a-f]{40}$/i.test(input);
};

export const isENS = (input) => {
  // Simple check for ENS (it should contain a dot, e.g., "name.eth")
  return input.includes(".");
};

export const checkUser = async ({ walletId }) => {
  let user = {
    address: "",
    safleId: "",
    ENS: "",
  };

  try {
    if (isPublicAddress(walletId)) {
      // Call API to check if it is a valid public address
      const isValid = await isAddressValid(walletId);
      if (isValid) {
        const safleId = await resolveAddress(walletId);
        user.safleId = safleId;
        user.address = walletId;
      }
    } else if (isENS(walletId)) {
      // Call API to resolve ENS
      const publicAddress = await resolveEns(walletId);
      user.ENS = walletId;
      user.address = publicAddress;
    }
    // else if (walletId.includes("safle-")) {
    //   // Call API to resolve Address
    //   const publicAddress = await resolveAddress(walletId);
    //   user.safleId = walletId;
    //   user.address = publicAddress;
    // }
    else {
      // Call API to resolve SafleId
      const publicAddress = await resolveSafleId(walletId);
      user.safleId = walletId;
      user.address = publicAddress;
    }

    return user;
  } catch (error) {
    // Handle error (log it or throw it)
    console.error("Address not found", error);
    return "Address not found";
    // throw error;
  }
};
