import React, { useEffect, useState } from "react";
import { DownloadAppButton } from "./DownloadAppPopUp";
import useStore from "../store";
import safleLogo from "../assets/SafleLens.svg";
import SafleLogoLightMode from "../assets/Icons/safle-logo-lightMode.svg";
import { MdLightMode, MdDarkMode } from "react-icons/md";

export const NavbarHome = () => {
  const { theme, setTheme } = useStore();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDoubleClick = () => {
    setIsModalOpen(true);
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

    return (
      <div className="flex overflow-hidden justify-evenly p-4 bg-white dark:bg-[#141719] dark:text-white text-black w-full">
        <div className="pr-[30rem]">
          <img
            src={theme === "light" ? SafleLogoLightMode : safleLogo}
            alt="Safle Logo"
            className="w-[9rem] h-[3rem] transition-opacity duration-200"
          />
        </div>

        <div className="flex flex-row items-center">
          <div className="flex pr-4">
            <DownloadAppButton />
          </div>

          {theme === "light" ? (
            <div
              onClick={toggleTheme}
              onDoubleClick={handleDoubleClick}
              className="theme-toggle prevent-select cursor-pointer transition-all duration-200 hover:scale-110 active:scale-95"
            >
              <MdDarkMode size={24} />
            </div>
          ) : (
            <div
              onClick={toggleTheme}
              className="theme-toggle prevent-select cursor-pointer transition-all duration-200 hover:scale-110 active:scale-95"
            >
              <MdLightMode size={24} />
            </div>
          )}
        </div>

        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0.5; }
              to { opacity: 1; }
            }

            img {
              animation: fadeIn 0.2s ease-in-out;
            }

            .prevent-select {
              user-select: none; /* Prevent text selection */
            }
          `}
        </style>
      </div>
    );
};
