import React, { useState, useEffect } from "react";
import SearchIcon from "../assets/Icons/Search.svg";
import "react-toastify/dist/ReactToastify.css";
import {
  AssetTable,
  NetworkDropdown,
  ProfileInfo,
  MoreTokenFilter,
} from "../components";
import useStore from "../store";

export const TokensScreen = () => {
  const [assetsList, setAssetsList] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showError, setShowError] = useState(false); // Added state to control error toast
  const { activeChains, chainWorth, tokenFilters } = useStore();

  useEffect(() => {
    if (chainWorth && chainWorth.length > 0) {
      const activeChainList = chainWorth.filter((chain) =>
        activeChains.includes(chain?.chain?.toLowerCase())
      );
      const tokenList = activeChainList.reduce((acc, chain) => {
        let assets = chain.assets.map((asset) => ({
          contract_name: asset.contract_name,
          contract_ticker_symbol: asset.contract_ticker_symbol,
          balance: asset.balance,
          quote: asset.quote,
          quote_rate: asset.quote_rate,
          logo_url: asset.logo_url,
          quote_rate_24h: asset.quote_rate_24h,
          quote_pct_change_24h: asset.quote_pct_change_24h,
          verified: asset.verified,
          chainName: chain.chain,
        }));

        if (tokenFilters?.includes("hidespam")) {
          assets = assets?.filter((asset) => asset.verified);
        }

        if (tokenFilters?.includes("hidelowbalance")) {
          assets = assets?.filter((asset) => asset.quote > 1);
        }
        return acc.concat(assets);
      }, []);
      const sortedTokenList = tokenList?.sort((a, b) => b.quote - a.quote);
      setAssetsList(sortedTokenList);
      setFilteredAssets(sortedTokenList);
    }
  }, [chainWorth, activeChains, tokenFilters]);

  useEffect(() => {
    const filtered = assetsList.filter(
      (asset) =>
        asset.contract_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        asset.contract_ticker_symbol
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredAssets(filtered);

    if (searchQuery && filtered.length === 0 && !showError) {
      // showErrorToast("No tokens match your search query.");
      setShowError(true); // Set error flag to true
    } else if (filtered.length > 0) {
      setShowError(false); // Reset error flag if there are matches
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, assetsList]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex flex-col text-black dark:text-white w-full divide-y-[1px] divide-[#1A1D20]">
      <ProfileInfo showChainWorth screenName="token" />
      <div className="flex flex-col relative">
        <div
          className={`flex flex-row w-full justify-between my-2 md:gap-4 py-4 duration-300 sticky top-[-17px] bg-white dark:bg-[#101113]`}
          style={{ zIndex: 100 }}
        >
          <div className="flex justify-between md:gap-4 pl-[1rem]">
            <NetworkDropdown />
            <MoreTokenFilter />
          </div>
          <div className="relative md:w-[360px] pr-2">
            <div className="relative">
              <img
                src={SearchIcon}
                alt="Search Icon"
                className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 invert-0 dark:invert"
              />
              <input
                type="text"
                placeholder="Search Token"
                value={searchQuery}
                onChange={handleInputChange}
                className="pl-10 pr-4 px-4 py-2 bg-white dark:bg-[#101113] text-base rounded-[20px] border-[1px] border-[#1A1D20] hidden md:block md:w-[340px] placeholder-custom"
              />
            </div>
          </div>
        </div>
        <div className="">
          {searchQuery && filteredAssets.length === 0 ? (
            <div className="text-center text-black dark:text-white py-4">
              Token not found
            </div>
          ) : (
            <AssetTable assets={filteredAssets} />
          )}
        </div>
      </div>
    </div>
  );
};

