import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../store";
import { checkUser, isPublicAddress, isENS } from "../utils/helper";
import SearchIcon from "../assets/Icons/Search.svg";
import { DownloadAppButton } from "./DownloadAppPopUp";
import "react-toastify/dist/ReactToastify.css";
import RightArrow from "../assets/Icons/Arrow.svg";
import ThemeIcon from "../assets/Icons/theme.svg";
import LightModeIcon from "../assets/Icons/theme.svg";
import DarkModeICON from "../assets/Icons/Mode.svg";
import { MdLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser, sideBar, setSideBar, theme, setTheme } = useStore();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Only load theme from localStorage on initial mount
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Immediate localStorage update
  };

  const getTitle = (pathname) => {
    switch (pathname) {
      case "/":
        return "Home";
      case "/featureRequest":
        return "Request a Feature";
      default:
        if (pathname.includes("/tokens")) return "Tokens";
        if (pathname.includes("/nfts")) return "NFTs";
        if (pathname.includes("/transactions")) return "Transactions";
        return "Dashboard";
    }
  };

  const handleInputChange = debounce(async (e) => {
    setSearchQuery(e.target.value);
    setLoading(true);
    setSuccess(false);
    setError(false);

    const walletId = e.target.value;
    try {
      const response = await checkUser({ walletId });
      setLoading(false);
      setError(false);

      if (e.target.value === "") {
        setButtonDisabled(true);
        return;
      }
      if (response.ENS) {
        setSuccess("ENS Detected");
        setButtonDisabled(false);
      } else if (response.address && walletId.includes("0x")) {
        setSuccess("Public Address Detected");
        setButtonDisabled(false);
      } else if (response.safleId) {
        setSuccess("SafleID Detected");
        setButtonDisabled(false);
      } else {
        if (!isPublicAddress(walletId) && walletId.includes("0x")) {
          setError("Public Address not found");
        } else if (isENS(walletId)) {
          setError("ENS not found");
        } else {
          setError("SafleID not found");
        }
        setButtonDisabled(true);
      }
    } catch (error) {
      setError("Error fetching user");
      setLoading(false);
    }
  }, 100);

  // Handle Enter key press
  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const handleIconClick = async () => {
    if (searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const toggleSidebar = () => {
    setSideBar(!sideBar);
  };

  return (
    <div className="w-full flex justify-between items-center p-4 dark:bg-[#101113] dark:text-white text-black bg-white border-[1px] dark:border-[#1A1D20]">
      <div className="flex items-center">
        {/* <button
          className="block lg:hidden text-2xl mr-4"
          onClick={toggleSidebar}
        >
          &#9776;
        </button> */}
      
        <h1 className="text-lg lg:text-xl">{getTitle(location.pathname)}</h1>
      </div>

      <div className="relative overflow-hidden">
        <div className="flex items-center justify-center mt-4">
          <div className="relative lg:w-[500px]">
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="absolute left-5 top-1/2 transform -translate-y-1/2 w-5 h-5 dark:invert lg:block"
            />
            <input
              type="text"
              onChange={(e) => handleInputChange(e)}
              onKeyDown={handleKeyPress}
              placeholder="Enter SafleID, Address or ENS"
              className={`pl-10 pr-4 px-4 py-2 dark:bg-[#101113] text-base rounded-full border-[1px] lg:w-[500px] outline-none
                ${
                  error
                    ? "border-red-500"
                    : success
                    ? "border-green-500"
                    : "border-[#1A1D20]"
                }`}
              style={{
                paddingLeft: "3rem",
                paddingRight: "3rem",
              }}
            />
            <img
              src={RightArrow}
              alt="Right Arrow"
              className={`absolute top-1/2 transform -translate-y-1/2 ${
                buttonDisabled
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer"
              } rounded-full bg-blue-500 p-1 right-1 transition-opacity duration-200`}
              onClick={handleIconClick}
            />
          </div>
        </div>

        <div className="items-center justify-center">
          {error && <div className="text-red-500 text-center">{error}</div>}
          {success && (
            <div className="text-green-500 text-center">{success}</div>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center">
        <DownloadAppButton />
        <div className="pl-4">
          {theme === "light" ? (
            <div
              onClick={toggleTheme}
              onMouseDown={(e) => e.preventDefault()}
              className="cursor-pointer transition-all duration-200 hover:scale-110 active:scale-95"
            >
              <MdDarkMode size={24} />
            </div>
          ) : (
            <div
              onClick={toggleTheme}
              onMouseDown={(e) => e.preventDefault()}
              className="cursor-pointer transition-all duration-200 hover:scale-110 active:scale-95"
            >
              <MdLightMode size={24} />
            </div>
          )}
        </div>
      </div>

    </div>
  );
};
