import React, { useState } from "react";
import FaviconIcon from "../assets/Icons/Favicon.svg";
import AppPopUpDark from "../assets/Icons/AppPopUpDark.jpg";
import AppPopUpLight from "../assets/Icons/AppPopUpLight-1.jpg";
import useStore from "../store";

export const DownloadAppButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useStore((state) => state.theme);

  const openModal = () => {
    setIsModalOpen(true);  // Open modal on single click
  };

  const closeModal = () => {
    setIsModalOpen(false);  // Close modal when clicked outside or on image
  };

  return (
    <div>
      <a
        className="flex w-[11.5625 rem] h-[3 rem] items-center bg-blue-500 text-white text-sm py-2 px-4 hover:bg-blue-700 rounded-full cursor-pointer"
        onClick={openModal} // Open modal on single click
      >
        <img src={FaviconIcon} alt="Logo" className="mr-2" />
        Download Safle Wallet
      </a>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-[#141517CC] backdrop-blur-sm flex items-center justify-center"
          style={{ zIndex: 1000, userSelect: "none" }}
          onClick={closeModal}
        >
          {theme === "light" ? (
            <img
              src={AppPopUpLight}
              alt="App Download Popup Light"
              className="w-[800px] h-[600px] rounded-[5%]"
              loading="lazy"
              onClick={closeModal}
            />
          ) : (
            <img
              src={AppPopUpDark}
              alt="App Download Popup Dark"
              className="w-[800px] h-[600px] rounded-[5%]"
              loading="lazy"
              onClick={closeModal}
            />
          )}
        </div>
      )}
    </div>
  );
};
