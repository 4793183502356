import React from "react";

export const WalletNotFound = () => {
  return (
    <div className="flex flex-col text-white w-full divide-y-[1px] divide-[#1A1D20]">
      404 Address not found
    </div>
  );
};

