// src/components/ChainDropdown.js
import React, { useState, useEffect, useRef } from "react";
import useStore from "../store";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import SearchIcon from "../assets/Icons/Search.svg";
import CheckIcon from "../assets/Icons/Check.svg";

export const NetworkDropdown = () => {
  const [search, setSearch] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { chains, activeChains, setActiveChains, chainWorth } = useStore();
  const dropdownRef = useRef(null);

  // Helper to find the chain with max balance
  const getMaxBalanceChain = () => {
    let maxChain = chains[0]?.name?.toLowerCase();
    let maxBalance = -Infinity;

    chains.forEach((chain) => {
      const chainName = chain?.name?.toLowerCase();
      const balance = chainWorth[chainName]?.net_asset_worth || 0;

      if (balance > maxBalance) {
        maxBalance = balance;
        maxChain = chainName;
      }
    });

    return maxChain;
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (!dropdownOpen) setSearch("");
  }, [dropdownOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleChain = (chainName) => {
    setActiveChains(
      activeChains.includes(chainName)
        ? activeChains.filter((name) => name !== chainName)
        : [...activeChains, chainName]
    );
  };

  const selectAllChains = () => {
    if (activeChains.length !== chains.length) {
      // Select all chains
      setActiveChains(chains.map((chain) => chain?.name?.toLowerCase()));
    } else {
      // Deselect all and select the max balance chain
      const maxChain = getMaxBalanceChain();
      setActiveChains([maxChain]);
    }
  };

  const filteredChains = chains.filter((chain) =>
    chain?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const sortedActiveChains = chains
    .filter((chain) => activeChains.includes(chain?.name?.toLowerCase()))
    .sort(
      (a, b) =>
        chains.indexOf(a?.name?.toLowerCase()) -
        activeChains.indexOf(b?.name?.toLowerCase())
    )
    .reverse();

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-4 py-2 bg-transparent text-black dark:text-white rounded-3xl border-[1px] border-[#25292D] flex flex-row items-center gap-2"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="relative w-[75px] h-[24px]">
          {sortedActiveChains[0] && (
            <img
              src={sortedActiveChains[0]?.logo_url}
              alt={sortedActiveChains[0]?.name}
              className={`w-[24px] h-[24px] left-[0rem] absolute`}
            />
          )}
          {sortedActiveChains[1] && (
            <img
              src={sortedActiveChains[1]?.logo_url}
              alt={sortedActiveChains[1]?.name}
              className={`w-[24px] h-[24px] left-[1.1rem] absolute`}
            />
          )}
          {sortedActiveChains[2] && (
            <img
              src={sortedActiveChains[2]?.logo_url}
              alt={sortedActiveChains[2]?.name}
              className={`w-[24px] h-[24px] left-[2.2rem] absolute`}
            />
          )}
          {sortedActiveChains.length - 3 > 0 && (
            <div className="w-[24px] h-[24px] bg-gray-600 flex justify-center items-center rounded-full absolute left-[3.3rem] text-[12px]">
              +{sortedActiveChains.length - 3}
            </div>
          )}
        </div>
        <div>{activeChains.length}</div>
        <div>Networks</div>
        <div>
          <img
            src={ToggleIcon}
            alt="Check Icon"
            className="invert-0 dark:invert h-[17px] w-[17px]"
          />
        </div>
      </button>
      {dropdownOpen && (
        <div className="absolute mt-2 w-56 text-black dark:text-white bg-white dark:bg-[#141719] border border-[#25292D] rounded-lg shadow-lg">
          <div className="flex items-center justify-between px-4 py-4 border-b-[1px] border-[#1A1D20]">
            <div className="relative">
              <img
                src={SearchIcon}
                alt="Search Icon"
                className="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 invert"
                style={{
                  filter: "invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg)",
                }}
              />
              <input
                type="text"
                placeholder="Search"
                className="w-full pl-8 px-2 py-1 border border-[#25292D] rounded-2xl text-black dark:text-white bg-transparent"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="ml-4">All</div>
            <button
              className="ml-2 px-2 py-1 flex flex-row items-center text-black dark:text-white gap-2"
              onClick={selectAllChains}
            >
              <div className="w-[20px] h-[20px] border-[1.5px] dark:border-white rounded-md border-black">
                {activeChains.length === chains.length && (
                  <img src={CheckIcon} alt="Check Icon" className="invert-0 dark:invert" />
                )}
              </div>
            </button>
          </div>
          <div className="max-h-60 overflow-y-auto no-scrollbar">
            {filteredChains.map((chain) => (
              <div
                key={chain?.name?.toLowerCase()}
                onClick={() => toggleChain(chain?.name?.toLowerCase())}
                className="flex items-center justify-between px-2 py-2 dark:hover:bg-[#1A1D20] rounded-md cursor-pointer text-black dark:text-white hover:bg-[#F2F2F2]"
              >
                <div className="flex items-center gap-4">
                  <div className="w-[24px] h-[24px] overflow-hidden rounded-full">
                    <img src={chain?.logo_url} alt="chain logo" className="h-full" />
                  </div>
                  <span>{chain?.name}</span>
                </div>
                <button
                  className={`${
                    activeChains.includes(chain?.name?.toLowerCase()) ? "block" : "hidden"
                  } mr-2`}
                >
                  <img src={CheckIcon} alt="Check Icon" className="invert-0 dark:invert" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
