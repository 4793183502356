import { create } from "zustand";
import { persist } from "zustand/middleware";
import { addDays } from "date-fns";

// Create the store
const useStore = create(
  persist(
    (set, get) => ({
      user: { address: "", safleId: "", ENS: "" },
      sideBar: false,
      chains: [],
      chainWorth: [],
      nftCollection: [],
      transactions: [],
      activeChains: [],
      theme: "light",
      tokenFilters: [],
      transactionTypes: ["all"],
      refresh: 0,
      tokenRefreshTime: 0,
      nftRefreshTime: 0,
      txnRefreshTime: 0,
      activeCurrency: "usd",
      conversionRate: 1,
      dateFilter: {
        startDate: 0,
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
      isAIActive: false,
      chatContext: "",
      activeAIToken: null,
      setUser: (user) => set({ user }),
      setSideBar: (sideBar) => set({ sideBar }),
      setChains: (chains) => set({ chains }),
      setChainWorth: (chainWorth) => set({ chainWorth }),
      setNftCollection: (nftCollection) => set({ nftCollection }),
      setTransactions: (transactions) => set({ transactions }),
      setActiveChains: (activeChains) => set({ activeChains }),
      setTokenFilters: (tokenFilters) => set({ tokenFilters }),
      clearChat: false,
      setClearChat: (clearChat) => set ({clearChat}),

      setTransactionTypes: (transactionTypes) => set({ transactionTypes }),
      setRefresh: (refresh) => set({ refresh }),
      setTokenRefreshTime: (tokenRefreshTime) => set({ tokenRefreshTime }),
      setNftRefreshTime: (nftRefreshTime) => set({ nftRefreshTime }),
      setTxnRefreshTime: (txnRefreshTime) => set({ txnRefreshTime }),
      setActiveCurrency: (activeCurrency) => set({ activeCurrency }),
      setConversionRate: (conversionRate) => set({ conversionRate }),
      setDateFilter: (dateFilter) => set({ dateFilter }),
      setIsAIActive: (isAIActive) => set({ isAIActive }),
      setChatContext: (chatContext) => set({ chatContext }),
      setActiveAIToken: (activeAIToken) => set({ activeAIToken }),
      setTheme: (theme) => {
        set({ theme });
        const root = window.document.documentElement;
        if (theme === "dark") {
          root.classList.add("dark");
        } else {
          root.classList.remove("dark");
        }
        localStorage.setItem("theme", theme);
      },
      toggleTheme: () => {
        const currentTheme = get().theme;
        const newTheme = currentTheme === "light" ? "dark" : "light";
        get().setTheme(newTheme);
      },
    }),
    {
      name: "my-storage", // name of the item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
      partialize: (state) => ({
        // user: state.user,
        activeCurrency: state.activeCurrency,
        conversionRate: state.conversionRate,
        transactionTypes: state.transactionTypes,
        theme: state.theme, // Add theme to persisted state
      }),
    }
  )
);

export default useStore;
