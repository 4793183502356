import React, { useState, useEffect, useRef } from "react";
import useStore from "../store";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import { filters } from "../utils/txnType";
import { FaCheck } from "react-icons/fa";

export const TxnTypeDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { transactionTypes, setTransactionTypes } = useStore();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilter = (key) => {
    let newTypes;
    if (key === "all") {
      if (transactionTypes.includes("all")) {
        newTypes = [];
      } else {
        newTypes = ["all"];
      }
    } else {
      if (transactionTypes.includes(key)) {
        newTypes = transactionTypes.filter((type) => type !== key);
      } else {
        newTypes = [...transactionTypes.filter((type) => type !== "all"), key];
      }
      // if (newTypes.length === 0) {
      //   newTypes = ["all"];
      // }
    }
    setTransactionTypes(newTypes);
  };

  const isSelected = (key) => {
    return transactionTypes.includes(key);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-4 py-2 bg-transparent text-black dark:text-white rounded-3xl border-[1px] border-[#25292D] flex flex-row items-center gap-2"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div>Transaction Types</div>
        <div>
          <img
            src={ToggleIcon}
            alt="Toggle Icon"
            className="invert-0 dark:invert h-[17px] w-[17px]"
          />
        </div>
      </button>
      {dropdownOpen && (
        <div className="absolute mt-2 w-48 bg-white dark:bg-[#141719] border border-[#25292D] rounded-lg shadow-lg z-10">
          <div className="max-h-60 overflow-y-auto no-scrollbar">
            <div
              className="flex items-center justify-end px-4 py-2 cursor-pointer border-b border-gray-700"
              onClick={() => toggleFilter("all")}
            >
              <span>All</span>
              <div className="flex-shrink-0 w-5 h-5 ml-3 border border-black dark:border-white rounded-md flex items-center justify-center">
                {isSelected("all") && (
                  <FaCheck className="text-black dark:text-white text-xs" />
                )}
              </div>
            </div>
            {filters.map((filter) => (
              <div
                key={filter.key.toLowerCase()}
                onClick={() => toggleFilter(filter.key.toLowerCase())}
                className="flex items-center justify-between px-4 py-3 hover:bg-slate-200 dark:hover:bg-[#1A1D20] rounded-md cursor-pointer"
              >
                <div className="flex">
                  {filter.icon && (
                    <img
                      src={filter.icon}
                      alt="Icon"
                      className="mr-2 w-6 h-6"
                    />
                  )}
                  <span>{filter.name}</span>
                </div>
                <div className="flex-shrink-0 w-4 h-4 rounded-sm flex items-center justify-center">
                  {(isSelected(filter.key.toLowerCase()) ||
                    isSelected("all")) && (
                    <FaCheck className="text-black dark:text-white text-xs" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
