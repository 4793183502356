import React, { useState, useEffect, useRef } from "react";
import ToggleIcon from "../assets/Icons/Arrow - Down.svg";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import useStore from "../store";
import CloseIcon from "../assets/Icons/close.svg";

export const CalendarDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { setDateFilter, theme, setIsAIActive } = useStore(); // Destructure theme from store
  const [state, setState] = useState([
    {
      startDate: 0,
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    setDateFilter(state[0]);
  }, [state, setDateFilter]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Determine if the current theme is dark
  const isDarkMode = theme === "dark";

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="px-4 py-2 bg-transparent text-black dark:text-white rounded-3xl border-[1px] border-[#25292D] flex flex-row items-center gap-2"
        onClick={() => {
          setIsAIActive(false);
          state[0]?.startDate === 0
          ? setDropdownOpen(!dropdownOpen)
          : setState([
              {
                startDate: 0,
                endDate: addDays(new Date(), 0),
                key: "selection",
              },
            ],
          
          )
        }
       
        }
      >
        {state[0]?.startDate === 0 ? (
          <>
            <div>Date</div>
            <img
              src={ToggleIcon}
              alt="Toggle Icon"
              className="invert-0 dark:invert h-[17px] w-[17px]"
            />
          </>
        ) : (
          <>
            <div>
              {new Date(state[0]?.startDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              -{" "}
              {new Date(state[0]?.endDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <img
              src={CloseIcon}
              alt="Clear Icon"
              className="invert-0 dark:invert h-[17px] w-[17px]"
            />
          </>
        )}
      </button>

      {dropdownOpen && (
        <div className="absolute mt-2 w-[400px] shadow-lg z-10 items-center duration-300">
          <img
            src={CloseIcon}
            onClick={() => setDropdownOpen(false)}
            alt="Clear Icon"
            className="invert h-[20px] w-[20px] bg-gray-300 rounded-full right-2"
            style={{
              filter: "invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg)",
            }}
          />

          {isDarkMode && (
            <style>
              {`
                .rdrDateDisplayWrapper {
                  background-color: #141719;
                }
                .rdrDateDisplayItem {
                  background-color: #141719 !important;
                  color: #ffffff;
                }
                .rdrMonthAndYearWrapper {
                  background-color: #141719 !important;
                }
                .rdrDayDisabled,
                .rdrDayStartOfMonth {
                  background-color: #141719 !important;
                }
                .rdrCalendarWrapper {
                  background-color: #141719;
                  border: 1px solid #25292D;
                }
                .rdrDayNumber span {
                  color: #ffffff !important;
                  background-color: transparent !important;
                }
                .rdrSelected,
                .rdrStartEdge,
                .rdrEndEdge {
                  background: #0669F8 !important;
                }
                .rdrYearPicker select option,
                .rdrDefinedRangesWrapper {
                  background-color: #141719;
                  color: #ffffff;
                  border: 1px solid #25292D;
                }
                .rdrMonthPicker select,
                .rdrYearPicker select {
                  border: 1px solid #25292D;
                }
                .rdrMonthPicker select option {
                  background-color: #ffffff;
                  color: #ffffff;
                }
                .rdrStaticRange {
                  background-color: #141719;
                  color: #ffffff;
                  border: none;
                }
                .rdrStaticRange:hover .rdrStaticRangeLabel,
                .rdrStaticRange:focus .rdrStaticRangeLabel {
                  background-color: #1A1D20;
                }
              `}
            </style>
          )}

          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            maxDate={new Date()}
            inputRanges={[]}
          />
        </div>
      )}
    </div>
  );
};
