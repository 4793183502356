import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { isAddress, getAddress as toChecksumAddress } from 'ethers';
import { NavbarHome } from "../components/NavbarHome";
import { Footer } from "../components";
import useStore from "../store";
import { checkUser, isPublicAddress, isENS } from "../utils/helper";
import SearchIcon from "../assets/Icons/Search-icon.svg";
import RightArrow from "../assets/Icons/Arrow.svg";
import Hero from "../assets/Hero.png";
import AutoDetect from "../assets/AutoDetect.svg";
import NetworkBalances from "../assets/networkbalance.svg";
import NftCollection from "../assets/nftcollection.svg";

export const NewHomeScreen = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { setUser } = useStore();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleInputChange = debounce(async (e) => {
    setSearchQuery(e.target.value);
    setLoading(true);
    setSuccess(false);
    setError(false);

    let walletId = e.target.value;
    // Convert to checksum address if valid
    if (isAddress(walletId)) {
        walletId = toChecksumAddress(walletId);
    }

    try {
        const response = await checkUser({ walletId });
        setLoading(false);
        setError(false);

        if (walletId === "") {
            setButtonDisabled(true);
            return;
        }

        // Check for ENS, public address, and Safle ID in order
        if (response.ENS) {
            setSuccess("ENS Detected");
            setButtonDisabled(false);
        } else if (response.address && walletId.includes("0x")) {
            setSuccess("Public Address Detected");
            setButtonDisabled(false);
        } else if (response.safleId) {
            setSuccess("SafleID Detected");
            setButtonDisabled(false);
        } else {
            // Handle error cases
            if (!isPublicAddress(walletId) && walletId.includes("0x")) {
                setError("Public Address not found");
            } else if (isENS(walletId)) {
                setError("ENS not found");
            } else {
                setError("SafleID not found");
            }
            setButtonDisabled(true);
        }
    } catch (error) {
        console.error("Error fetching user:", error);
        setError("Error fetching user");
        setLoading(false);
    }
}, 500);

  // Handle Enter key press
  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const handleIconClick = async () => {
    if (searchQuery) {
      setLoading(true);
      const user = await checkUser({ walletId: searchQuery });
      if (typeof user !== "string") {
        setUser(user);
        navigate(`/${searchQuery}`);
      } else {
        setError(user);
        setLoading(false);
      }
    }
  };

  const handleButtonClick = () => {
    window.open("sftsafle3", "_blank");
  };

  return (
    <div className="relative bg-white  dark:bg-[#141719] min-h-screen">
      <div className="sticky top-0 z-[10000]">
        <NavbarHome />
      </div>

      <div className="p-4 text-center">
        <div className="p-1 font-bold text-3xl text-[#BBBDBE]">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF7777] to-[#0669F8]">
            Your Crypto Portfolio, All in One Place
          </span>
        </div>
        <div className="p-2 dark:text-[#6D7072] text-black text-base border-none w-full flex flex-row justify-center">
          <div className="w-[50%]">
            Safle Lens is a powerful view-only crypto portfolio viewer,
            supporting 10 EVM chains for seamless tracking of any tokens and
            NFTs. Stay secure while managing your assets, with no private key
            access or transactions required. Safle Lens offers a streamlined
            solution for monitoring your crypto & asset holdings, giving you
            full visibility without compromising security.
          </div>
        </div>

        <div className="flex items-center z-30 justify-center mt-8">
          <div className="relative md:w-[500px]">
            <img
              src={SearchIcon}
              alt="Search Icon"
              className="absolute left-5 top-1/2 transform -translate-y-1/2 w-5 h-5 hidden md:block invert-0 dark:invert"
            />
            <input
              type="text"
              onChange={(e) => handleInputChange(e)}
              onKeyDown={handleKeyPress}
              placeholder="Enter SafleID, Address or ENS"
              className={`pl-10 pr-4 px-4 py-2 bg-white dark:text-white dark:bg-transparent text-base rounded-full border-[1px] md:w-[500px] outline-none ${
                error
                  ? "border-red-500"
                  : success
                  ? "border-green-500"
                  : "border-[#1A1D20]"
              }
                    placeholder:text-[#6D7072] dark:placeholder:text-[#6D7072]`}
            />
            {buttonDisabled ? (
              <img
                src={RightArrow}
                alt="Right Arrow"
                className="absolute top-1/2 transform -translate-y-1/2 opacity-50 cursor-not-allowed bg-[#1A1D20] rounded-full p-1 right-1 fade-in"
                onClick={handleIconClick}
              />
            ) : (
              <img
                src={RightArrow}
                alt="Right Arrow"
                className="absolute top-1/2 transform -translate-y-1/2 cursor-pointer rounded-full bg-[#1A1D20] p-1 right-1"
                onClick={handleIconClick}
              />
            )}
          </div>
          <div className="dark:text-[#6D7072] text-black p-4">or</div>

          <button
            className="flex w-[9rem] text-sm h-10 items-center bg-blue-500 text-white text-sm py-2 px-4 hover:bg-blue-700 rounded-full _blank"
            onClick={handleButtonClick}
          >
            Try Demo Wallet
          </button>
        </div>

        <div className="items-center justify-center h-[1rem] pr-[13rem] pt-1">
          {error && <div className="text-red-500 text-center">{error}</div>}
          {success && <div className="text-green-500 text-center">{success}</div>}
        </div>

        <div className="flex items-center justify-center pt-2 mb-8">
          {loading && searchQuery && (
            <div className="border-t-4 border-blue-500 rounded-full w-10 h-10 animate-spin"></div>
          )}
        </div>

        <div className="flex flex-row justify-center mb-8">
          <div className="pointer-events-none inset-0 -z-0 ">
            <div className="w-4/12 h-96 left-64 rounded-full bg-gradient-to-tr from-[#FF7777] to-[#0669F8] absolute blur-[100px]"></div>
          </div>
          <img src={Hero} alt="hero" className="z-20" />
        </div>

        <div className="p-1 font-bold text-3xl text-[#BBBDBE]">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF7777] to-[#0669F8]">
            Safle AI
          </span>
        </div>
        <div className="p-2 dark:text-white text-black text-base border-none w-full flex flex-row justify-center">
          <div className="w-[50%]">
            Ask Safle AI & discover a new horizon, and explore a world of
            possibilities and information. Whether you're asking about any
            tokens, or unlocking new information about NFTs, our AI is here to
            make it effortless.{" "}
          </div>
        </div>

        <div className="flex flex-row justify-center mb-8">
          <img src={AutoDetect} alt="hero" />
        </div>

        <div className="p-1 font-bold text-3xl text-[#BBBDBE]">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#FF7777] to-[#0669F8]">
            Effortless Portfolio Viewing
          </span>
        </div>
        <div className="p-2 dark:text-white text-black text-base border-none w-full flex flex-row justify-center">
          <div className="w-[50%]">
            Simply enter your SafleID, ENS or Address to instantly view your
            tokens, NFTs, and transaction history no login required.
          </div>
        </div>

        <div className="flex flex-row justify-center mb-8">
          <img src={NetworkBalances} alt="hero" />
        </div>

        <div className="flex flex-row justify-center mb-8">
          <img src={NftCollection} alt="hero" />
        </div>

        <Footer />
      </div>
    </div>
  );
};
