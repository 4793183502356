import React, { useState, useCallback, useEffect, useRef } from "react";
import CloseIcon from "../assets/Icons/close.svg";
import useStore from "../store";
import { questions } from "../utils/chatQns";
import { fetchQueryResponse } from "../api/safleAI";
import AIICON2 from "../assets/Icons/AI-icon-2.svg";
import AI_ICON_LIGHT from "../assets/Icons/AI_ICON_LIGHT-1.svg";
import ReactMarkdown from "react-markdown";
import NFTnotFound from "../assets/Icons/NFT-Placeholder.svg";

export const SafleAI = () => {
  const {
    chatContext,
    activeAIToken,
    setIsAIActive,
    setChatContext,
    setActiveAIToken,
    clearChat,
  } = useStore();
  const [chatHistory, setChatHistory] = useState([]);
  const [activeQuery, setActiveQuery] = useState("");
  const [streamingResponse, setStreamingResponse] = useState("");
  const [chatDisabled, setChatDisabled] = useState(false);
  const bottomRef = useRef(null);
  const theme = useStore((state) => state.theme);

  useEffect(() => {
    setChatHistory([]);
    setActiveQuery("");
  },
  [clearChat])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [chatHistory, streamingResponse]);

  function filterPayload(payload) {
    const filteredPayload = [];
    let lastRole = null;

    for (let i = payload.length - 1; i >= 0; i--) {
      const current = payload[i];

      if (current.role !== lastRole) {
        filteredPayload.unshift(current);
        lastRole = current.role;
      } else if (current.role === "assistant") {
        filteredPayload.unshift(current);
      }
    }

    return filteredPayload;
  }

  const getLast10Messages = () => {
    let recentMessages = chatHistory.slice(-10);
    if (chatContext === "token") {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /token/g,
                `${activeAIToken?.contract_name} (${activeAIToken?.contract_ticker_symbol})`
              ),
            }
          : obj
      );
    }
    if (chatContext === "nft") {
      recentMessages = recentMessages.map((obj, i) =>
        i === recentMessages.length - 1
          ? {
              ...obj,
              content: obj.content.replace(
                /NFT/g,
                `NFT of ${activeAIToken?.description || activeAIToken?.token_id} (${activeAIToken?.asset_contract} on ${activeAIToken?.chainName} chain)`
              ),
            }
          : obj
      );
    }
    return recentMessages;
  };


  const fetchResponse = useCallback(async () => {
    if (chatHistory.slice(-1)[0]?.role === "user") {
      setChatDisabled(true);
      try {
        const response = await fetchQueryResponse(
          filterPayload(getLast10Messages())
        );
        const reader = response.getReader();
        const decoder = new TextDecoder();

        let responseText = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });

          const dataLines = chunk.split("\n");
          dataLines.forEach((line) => {
            if (line.trim().startsWith("data:")) {
              const jsonStr = line.trim().slice(5);
              if (jsonStr) {
                try {
                  const jsonObj = JSON.parse(jsonStr);
                  const content = jsonObj.choices[0]?.delta?.content;
                  if (content) {
                    responseText += content;
                    setStreamingResponse(responseText);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }
            }
          });
        }

        setChatHistory((prev) => [
          ...prev,
          { role: "assistant", content: responseText },
        ]);
        setStreamingResponse("");
        setChatDisabled(false);
      } catch (error) {
        setStreamingResponse(error?.toString()?.slice(7));
        setChatDisabled(false);
      }
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory.slice(-1)[0]?.role === "user") {
      fetchResponse();
    }
  }, [chatHistory]);

  const handleInputChange = (e) => {
    if (streamingResponse === "Sorry, something went wrong.") {
      setChatHistory((prevChatHistory) => prevChatHistory.slice(0, -1));
      setStreamingResponse("");
    }
    setActiveQuery(e.target.value);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && activeQuery) {
      setChatHistory((prev) => [...prev, { role: "user", content: activeQuery }]);
      setActiveQuery("");
    }
  };

  return (
    <div className="absolute flex flex-col right-4 bottom-4 z-[1000] text-black dark:text-white h-[85%] min-w-[467px] w-[30%] rounded-lg border-[#25292D] dark:bg-[#1A1D20] bg-white">
      <div className="p-4 dark:bg-[#25292D] flex flex-row justify-between items-center bg-[#EFF1F7]">
        <div className="flex flex-row gap-2">
          <div className="w-[40px]">
            <img src={theme === "light" ? AI_ICON_LIGHT : AIICON2} alt="AI Icon" />
          </div>
          <div className="w-[176.5px] pt-2">Hi! This is Safle AI</div>
        </div>
        <div className="flex flex-row-reverse items-center gap-2">
          <div
            onClick={() => {
              setIsAIActive(false);
              setChatContext("");
              setActiveAIToken(null);
            }}
            className="cursor-pointer"
          >
            <img
              src={CloseIcon}
              alt="Clear Icon"
              className="invert h-[17px] w-[17px]"
              style={{ filter: "invert(29%) sepia(20%) saturate(277%) hue-rotate(192deg)" }}
            />
          </div>
          {chatContext === "token" && (
            <div className="w-[110px] flex items-center gap-2 px-4 py-1 dark:bg-[#1A1D20] bg-white">
              <img
                src={activeAIToken?.logo_url}
                alt="AI Icon"
                className="w-[20px] h-[20px] rounded-[10px]"
              />
              <div className="flex flex-col">
                <div className="text-sm">
                  {activeAIToken?.contract_ticker_symbol?.length > 10
                    ? `${activeAIToken?.contract_ticker_symbol.substring(0, 10)}...`
                    : activeAIToken?.contract_ticker_symbol}
                </div>
                <div className="text-xs text-[#6D7072]">
                  {activeAIToken?.contract_name?.length > 10
                    ? `${activeAIToken.contract_name.substring(0, 10)}...`
                    : activeAIToken?.contract_name}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="p-4 flex-grow overflow-scroll no-scrollbar">
        {chatHistory.length === 0 && (
          <div className="flex flex-col gap-2">
            {chatContext === "nft" && <div className="text-xs">{activeAIToken?.description}</div>}
            {chatContext === "nft" && (
              <div className="w-full h-40 flex items-center justify-center">
                <img
                  src={activeAIToken?.image_url}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = NFTnotFound;
                  }}
                  alt="NFT"
                />
              </div>
            )}
            <div className="text-[14px] text-[#6D7072]">Hi, How can I help you today?</div>
            <div className="flex flex-col gap-2 my-2">
              {questions?.[chatContext]?.map((qn, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setChatHistory((prev) => [...prev, { role: "user", content: qn }]);
                    setActiveQuery("");
                  }}
                  className="dark:bg-[#222529] p-2 rounded-[8px] border cursor-pointer bg-white border-[#E9EAEA]"
                >
                  {qn}
                </div>
              ))}
            </div>
          </div>
        )}

        {chatHistory.length > 0 && (
          <div className="flex flex-col gap-2">
            {chatHistory.map((chatItem, index) => (
              <div
                key={index}
                className={`flex ${
                  chatItem?.role === "assistant" ? "flex-row" : "flex-row-reverse"
                } items-start gap-2`}
              >
                <div className="dark:bg-[#222529] p-2 rounded-[8px] border bg-white border-[#E9EAEA]">
                  {chatItem.role === "assistant" ? (
                    <ReactMarkdown>{chatItem.content}</ReactMarkdown>
                  ) : (
                    chatItem.content
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {streamingResponse && (
          <div className="dark:bg-[#222529] p-2 rounded-[8px] border bg-white border-[#E9EAEA]">
            {streamingResponse}
          </div>
        )}
        <div ref={bottomRef} />
      </div>

      <div className="dark:bg-[#25292D] flex p-4">
        <input
          type="text"
          placeholder="Type your question here"
          value={activeQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={chatDisabled}
          className={`dark:bg-[#1A1D20] bg-white p-2 w-full rounded-lg ${
            chatDisabled ? "opacity-50" : ""
          }`}
        />
      </div>
    </div>
  );
};
